import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-30bd14d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "navDropdown",
  class: "relative"
}
const _hoisted_2 = { class: "absolute right-0 w-full md:w-64 mt-2 origin-top-right rounded-md shadow-lg z-10" }
const _hoisted_3 = { class: "bg-white rounded-md shadow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "flex flex-row items-center w-full rounded-full px-10 py-2 mt-2 md:text-lg text-sm border text-left bg-transparent md:w-auto md:inline md:mt-0 md:ml-4 hover:bg-black hover:border-black hover:text-white transition-colors focus:text-gray-900 focus:bg-gray-200 focus:outline-none focus:shadow-outline lg:max-w-lg whitespace-nowrap overflow-ellipsis overflow-hidden",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.open = !_ctx.open), ["prevent"]))
    }, _toDisplayString(_ctx.text), 1),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ], 512), [
          [_vShow, _ctx.open]
        ])
      ]),
      _: 3
    })
  ], 512))
}